<script setup>
	import { ref, computed, onMounted } from 'vue';
	import { DsGrid, DsText, DsButton, dsUseIsMobile } from '@coloplast/design-system';
	import VariantPortfolioBlockItem from './components/VariantPortfolioBlockItem.vue';
	import ModalGallery from 'shared/components/mediaItems/ModalGallery.vue';
	import { track_view_item, track_view_items } from 'shared/services/tracking/microSiteTrackingService.ts';
	import { formatToSnakeCase } from 'shared/services/transformDataService.js';

	const props = defineProps({
		vm: {
			type: Object,
			default: () => {},
		},
	});

	const { isMobile } = dsUseIsMobile();

	const cardsPerPage = 4;

	const displayedCards = ref(cardsPerPage);

	const slicedData = computed(() => {
		return props.vm.BaseViewModel.Cards.slice(0, displayedCards.value);
	});

	const showMore = () => {
		displayedCards.value += cardsPerPage;
		track_view_items(props.vm.CurrentBlock.Headline, formatToSnakeCase(props.vm.CurrentBlock.Headline), trackingItemList.value);
	};

	const showedModal = ref(false);
	const galleryItem = ref({});

	const showModal = (currentGalleryItem, currentGalleryIndex) => {
		showedModal.value = true;
		galleryItem.value = currentGalleryItem;
		track_view_item(currentGalleryIndex, formatToSnakeCase(currentGalleryItem.Headline), currentGalleryItem.Headline);
	};

	const hideModal = () => {
		showedModal.value = false;
		galleryItem.value = null;
	};

	const trackingItemList = computed(() => {
		let currentVisibleItemList = isMobile?.value ? slicedData.value : props.vm.BaseViewModel.Cards;

		return currentVisibleItemList.map((item, index) => {
			return {
				index: index,
				item_id: formatToSnakeCase(item?.Headline),
				item_name: item.Headline,
			}
		})
	});

	const variantPortfolio = ref(null);
	const observer = ref(null);
	const itemListTracked = ref(false);

	onMounted(() => {
		observer.value = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && !itemListTracked.value) {
				track_view_items(props.vm.CurrentBlock.Headline, formatToSnakeCase(props.vm.CurrentBlock.Headline), trackingItemList.value);
				itemListTracked.value = true;
			}
		});

		observer.value.observe(variantPortfolio.value);
	});
</script>

<template>
	<div
        ref="variantPortfolio"
		class="c-variant-portfolio__container"
		data-testid="variant-portfolio"
	>
		<DsGrid
			:columns="1"
			:columns-md="2"
			gap="md"
			html-element="section"
		>
			<DsText
				v-if="vm.CurrentBlock.Headline"
				html-element="h2"
				text-type="heading-3xl"
				class="c-variant-portfolio__headline"
			>
				{{ vm.CurrentBlock.Headline }}
			</DsText>
			<DsText
				v-if="vm.CurrentBlock.Description"
				class="c-variant-portfolio__description"
			>
				{{ vm.CurrentBlock.Description }}
			</DsText>
		</DsGrid>
		<DsGrid
			v-if="vm.BaseViewModel?.Cards?.length"
			:columns="1"
			:columns-md="2"
			:columns-lg="3"
			:columns-xl="4"
			gap="md"
			html-element="section"
		>
			<VariantPortfolioBlockItem
				v-for="(item, index) in isMobile ? slicedData : vm.BaseViewModel.Cards"
				:key="`${item.Headline}-${index + 1}`"
				:item="item"
				@click="showModal(item, index)"
			/>
		</DsGrid>
		<ModalGallery
			v-if="showedModal"
			:gallery-item="galleryItem"
			@hidden-modal="hideModal"
		/>
		<DsButton
			v-if="isMobile && vm.BaseViewModel.Cards.length > slicedData.length"
			type="button"
			variant="ghost"
			icon-end="add"
			class="c-variant-portfolio__button"
			:click-handler="showMore"
		>
			{{ vm.CurrentBlock.ShowMoreButtonLabel }}
		</DsButton>
	</div>
</template>
