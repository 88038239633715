import { createApp } from 'vue';
import store from 'shared/store';
import VariantPortfolioBlock from './VariantPortfolioBlock.vue';
import './c-variant-portfolio.scss';

const variantPortfolioBlock = document.getElementById('variant-portfolio-block');

if (variantPortfolioBlock) {
	const app = createApp({
		name: 'VariantPortfolioBlockApp',
	});
	app.use(store);
	app.component('variant-portfolio-block', VariantPortfolioBlock);
	app.mount(variantPortfolioBlock);
}