import { trackEcommerce } from './core';
import { trackingEventNames } from '../../settings/constants';
import { IHasTrackingParameters } from './interfaces/IHasTrackingParameters';

export function track_view_item(itemIndex: number, itemId: string, itemHeadline: string) {
	const event = {
		event: trackingEventNames.VIEW_ITEM,
		ecommerce: {
			items: [
				{
					index: itemIndex,
					item_id: itemId,
					item_name: itemHeadline,
				}
			]
		}
	};
	trackEcommerce(event);
}

export function track_view_items(itemListHeadline: string, itemListId: string, itemList: Array<IHasTrackingParameters>) {
	const event = {
		event: trackingEventNames.VIEW_ITEM_LIST,
		ecommerce: {
			item_list_name: itemListHeadline,
			item_list_id: itemListId,
			items: itemList,
		}
	};
	trackEcommerce(event);
}